
















































import {Component, Vue} from "vue-property-decorator";
import DepartmentService from "@/services/DepartmentService";
import DepartmentContent from "@/models/DepartmentContent";

@Component
export default class DepartmentsView extends Vue {
    loading: boolean = false
    departments: DepartmentContent[] = []

    created() {
        DepartmentService.getDepartments(this, this.departments)
    }
}
